import React, { useContext, useEffect, useState } from 'react';
import { AppSettings } from '../../config/app-settings';
import { useDispatch, useSelector } from 'react-redux';
import { listWhiteList } from '../../redux/apis/whitelistApi';
import Loader from '../pages/loader';
import ReusableTable from '../../components/other/ReuseableTable';
import EditCreateChannel from '../../components/WhitelistChannels/EditCreateChannel'
import { can } from '../../helpers/auth';
import NoPermission from '../pages/noPermission';
import { toast } from 'react-toastify';
import { clearCreateUpdateRes, clearError } from '../../redux/slices/whitelistSlice';

function WhitelistChannels() {
	const dispatch = useDispatch();
	const { whitelist, createUpdateRes, isLoading, error } = useSelector(state => state.whitelist);
	const [edit, setEdit] = useState(false)
	const [create, setCreate] = useState(false)
	const [holder, setHolder] = useState()

	useEffect(() => {
		if (!whitelist || !whitelist?.[0]?.last_updated_by) {
			dispatch(listWhiteList({
				expand: ['customer', 'last_updated_by'].join(','),
				page: '*',
				ordering: '-id'
			}));
		}
	}, []);

	useEffect(() => {
		if (!createUpdateRes) return
		if (createUpdateRes?.invalid == 0) {
			toast.success("All links added to whitelist.")
			dispatch(clearCreateUpdateRes())
		} else if (createUpdateRes?.invalid > 0) {
			toast.error(`Error while adding to whitelist, ${createUpdateRes?.invalid} Links not added`)
			dispatch(clearCreateUpdateRes())
		}
	}, [createUpdateRes])

	let columns = [
		{ title: "ID", dataIndex: "id", key: "id", sorter: true },
		{
			title: 'Channel Name',
			dataIndex: 'channel_name',
			key: 'channel_name',
			sorter: true,
			search: true,
		},
		{
			title: 'Channel ID',
			dataIndex: 'channel_id',
			key: 'channel_id',
			sorter: true,
			search: true,
		},
		{
			title: 'Channel URL',
			dataIndex: 'channel_url',
			key: 'channel_url',
			sorter: true,
			search: true,
		},
		{
			title: 'Customer',
			dataIndex: 'customer',
			key: 'customer.customer_name',
			sorter: true,
			search: true,
			render: (customer) => customer ? customer.customer_name : '',
		},
		{
			title: 'Last Updated By',
			dataIndex: 'last_updated_by',
			key: 'last_updated_by',
			render: (text, record) => {
				const { last_updated_by } = record;
				return last_updated_by ? `${last_updated_by.first_name} ${last_updated_by.last_name}` : '';
			},
		},

	];

	if (can("change_whitelistchannel")) {
		columns.push({
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<div className='flex gap-2'>
					<button className='btn btn-outline-theme' onClick={() => handleEdit(record)}>Edit</button>
				</div>
			),
		})
	}
	const handleEdit = (record) => {
		setHolder(record)
		setEdit(true)
	};

	const context = useContext(AppSettings);
	useEffect(() => {
		context.setAppHeaderNone(false);
		context.setAppSidebarNone(false);
		context.setAppContentClass('');
		context.setAppThemePanal(true)

		return function cleanUp() {
			context.setAppHeaderNone(true);
			context.setAppSidebarNone(true);
			context.setAppContentClass('p-0');
			context.setAppThemePanal(false)
		};
	}, []);

	useEffect(() => {
		if (!error && !isLoading) {
			return
		}
		toast.error(error)
		dispatch(clearError())
	}, [error, isLoading])


	if (!can("view_whitelistchannel")) {
		return <NoPermission />
	}

	return (
		<div className='w-full flex flex-col'>
			<Loader isLoading={isLoading} />
			{edit ? <EditCreateChannel state={"edit"} open={edit} setOpen={setEdit} channel={holder} /> : null}
			{create ? <EditCreateChannel state={"create"} open={create} setOpen={setCreate} channel={null} /> : null}
			<button className='btn btn-outline-theme ml-auto' onClick={() => setCreate(true)}>Add New Channel</button>
			<br />
			{whitelist ?
				<ReusableTable
					data={whitelist}
					columns={columns}
				/>
				: null
			}
		</div>
	)
}

export default WhitelistChannels;