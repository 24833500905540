import React, { useContext, useEffect } from 'react';
import { AppSettings } from '../../config/app-settings';
import { Card, CardBody, CardFooter, CardHeader } from '../../components/other/card/card'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateVideoBulk } from '../../redux/apis/videosApi';
import Loader from '../pages/loader'
import CampaignDropdown from '../../components/CampaignDropdown';
import { getUserId } from '../../helpers/auth'
import { toast } from 'react-toastify';
import { can } from '../../helpers/auth';
import NoPermission from '../pages/noPermission';
import { clearBulkInsertion, clearError } from '../../redux/slices/videoSlice';

function ChangeLinksCampaign() {
	const dispatch = useDispatch()
	const { isLoading, bulkInsertion, error } = useSelector(state => state.video)


	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			url: '',
			campaign: null,
			last_updated_by: getUserId()
		},

		validationSchema: Yup.object({
			url: Yup.string().required('Please enter URLs'),
			campaign: Yup.number().required('Please select a campaign'),
		}),


		onSubmit: (values, { resetForm }) => {


			// toast.success("Links Updates Started, please wait...");


			// Make API call or perform desired action with the values
			const urls = values['url'].split('\n')
			// loop through groupedUrls and create a body for each url in each domain

			let details = {
				"campaign": values['campaign'],
				"last_updated_by": values['last_updated_by']
			}
			let body = urls.map((x) => {
				return {
					url: x,
					...details
				}
			})
			if (Object.keys(validation.errors).length > 0) {
				Object.keys(validation.errors).forEach((error) => {
					toast.error(validation.errors[error]);
				});
			}
			if (Object.keys(validation.errors).length === 0) {
				dispatch(updateVideoBulk(body))
				body = {};
				resetForm();
			}
		}

	});


	useEffect(() => {
		if (!bulkInsertion) return
		if (bulkInsertion?.invalid == 0) {
			toast.success(`Urls marked successfully.`
			)
			dispatch(clearBulkInsertion())
		}
	}, [bulkInsertion])

	useEffect(() => {
		if (Object.keys(validation.errors).length === 0 && !error && !isLoading) {
			validation.resetForm();
			return
		}
		toast.error(error)
		dispatch(clearError())
	}, [error, isLoading])



	const context = useContext(AppSettings);
	useEffect(() => {
		context.setAppHeaderNone(false);
		context.setAppSidebarNone(false);
		context.setAppContentClass('');
		context.setAppThemePanal(true)

		return function cleanUp() {
			context.setAppHeaderNone(true);
			context.setAppSidebarNone(true);
			context.setAppContentClass('p-0');
			context.setAppThemePanal(false)
		};
	}, []);

	if (!can("change_videourl")) {
		return <NoPermission />
	}

	return (
		<div>
			<Loader isLoading={isLoading} />
			<Card>
				<CardHeader>
					Change Links Assosiated Campaign
				</CardHeader>
				<CardBody>
					<div className="mb-3">
						<label htmlFor='url'>URLs (separated by new lines)</label>
						<br />
						<textarea
							name="url"
							value={validation.values.url}
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							placeholder='Enter url'
							style={{ height: "250px", width: '100%', backgroundColor: "transparent", border: '1px solid white', padding: "8px" }}
						/>
						{validation.touched.url && validation.errors.url && (
							<p className='text-danger'>{validation.errors.url}</p>
						)}
					</div>
					<div>
						<CampaignDropdown
							className='customSelect'
							value={validation.values.campaign}
							onChange={validation.setFieldValue}
							onBlur={validation.handleBlur}

						/>
						{validation.touched.campaign && validation.errors.campaign ? (
							<p className='text-danger'>{validation.errors.campaign}</p>
						) : null}
					</div>

				</CardBody>
				<CardFooter>
					<div className='flex gap-4'>
						<button type="submit" className="btn btn-outline-theme" onClick={(e) => validation.handleSubmit()}
							disabled={isLoading}
						>
							Submit
						</button>
					</div>
				</CardFooter>
			</Card>
		</div>
	)
}

export default ChangeLinksCampaign;