import React from 'react'
import { useDispatch } from 'react-redux';
import { updateLoa } from '../../redux/apis/campaignApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Input, Modal } from 'antd';
import CustomersDropdown from '../CustomersDropdown';
import { toast } from 'react-toastify';
import { clearCampaigns } from '../../redux/slices/campaignSlice';

const EditCreateLOA = ({ state, open, setOpen, campaign }) => {
    const dispatch = useDispatch()


    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            campaign_loa_dropbox_url: campaign?.campaign_loa_dropbox_url || "",
            customer: null,
            campaign: campaign?.id || null
        },
        validationSchema: Yup.object({
            campaign_loa_dropbox_url: Yup.string().required(`Campaign LOA Dropbox URL is required`),
            customer: Yup.number().nullable(),
            campaign: Yup.number().nullable(),
        }),
        onSubmit: async (values) => {
            if (Object.keys(validation.errors).length === 0) {
                setOpen(false)
            }
            dispatch(updateLoa(values))

            setTimeout(() => {
                dispatch(clearCampaigns());
            }, 1000);
        }
    });

    return (
        <Modal
            className='customModal'
            open={open}
            title={state === "edit" ? "Change LOA for Customer" : "Add LOA for Customer"}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={() => {
                    if (Object.keys(validation.errors).length !== 0) {
                        Object.keys(validation.errors).map((key) => {
                            toast.error(validation.errors[key]);
                        });
                        return;
                    }
                    validation.handleSubmit();
                }}>
                    Submit
                </Button>
            ]}
        >
            {state === "create" ?
                <>
                    <CustomersDropdown
                        onChange={e => { validation.setFieldValue("customer", e) }}
                        onBlur={validation.handleBlur}
                    />
                </> : null
            }
            <div className=" mb-3">
                <label className="" >LOA Dropbox URL</label>
                <Input
                    type="text"
                    name='campaign_loa_dropbox_url'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.campaign_loa_dropbox_url}
                />
            </div>
            <div className='flex flex-col'>
                {Object.keys(validation.errors).map((key) => (
                    <p className='text-danger'>{validation.errors[key]}</p>
                ))}
            </div>
        </Modal>
    )
}

export default EditCreateLOA