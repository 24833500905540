import React, { useContext, useEffect } from 'react';
import { AppSettings } from '../../config/app-settings';
import { Card, CardBody, CardFooter, CardHeader } from '../../components/other/card/card'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../pages/loader'
import { listVideos } from '../../redux/apis/videosApi';
import ReusableTable from '../../components/other/ReuseableTable';
import { toast } from 'react-toastify';
import { can } from '../../helpers/auth';
import NoPermission from '../pages/noPermission';
import { clearError } from '../../redux/slices/videoSlice';

function GetLinksDetails() {

	const dispatch = useDispatch()
	const { isLoading, videos, error } = useSelector(state => state.video)


	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			urls: ''
		},

		validationSchema: Yup.object({
			urls: Yup.string().required('Please enter URLs'),
		}),


		onSubmit: (values, { resetForm }) => {


			// toast.success('Links Inquiry Started, please wait...');

			const urls = values.urls.split('\n');
			console.log(urls)
			let data = urls.join(',')

			if (Object.keys(validation.errors).length > 0) {
				Object.keys(validation.errors).forEach((error) => {
					toast.error(validation.errors[error]);
				});
			}
			if (Object.keys(validation.errors).length === 0) {
				dispatch(listVideos({ url__in: data, expand: ["user", "inserted_by", "campaign"] }))
				resetForm();
			}
		}

	});
	function formatTimestamp(timestamp) {
		if (timestamp == null) return ""
		const date = new Date(timestamp);

		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0'); // months are zero-indexed
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	}

	const columns = [
		{
			title: "Link",
			dataIndex: "url",
			key: "url",
			sorter: true,
			search: true,
			width: "10%"
		},
		{
			title: 'Campaign',
			dataIndex: 'campaign',
			key: 'campaign.campaign_name',
			sorter: true,
			search: true,
			render: (text, record) => (
				record.campaign.campaign_name
			)
		},
		{
			title: 'Link Type',
			dataIndex: 'link_type',
			key: 'link_type',
			sorter: true,
			search: true,
		},
		{
			title: 'Inserted By',
			dataIndex: 'inserted_by',
			key: 'inserted_by',
			sorter: true,
			search: true,
			render: (text, record) => {
				const { inserted_by } = record;
				return inserted_by ? `${inserted_by.first_name} ${inserted_by.last_name}` : '';
			},
		},
		{
			title: 'Insertion Date',
			dataIndex: 'created_at',
			key: 'created_at',
			render: (text) => (formatTimestamp(text)),
		},
		{
			title: 'Report Date',
			dataIndex: 'report_date',
			key: 'report_date',
			render: (text) => (formatTimestamp(text)),

		},
		{
			title: 'Remove Date',
			dataIndex: 'remove_date',
			key: 'remove_date',
			render: (text) => ( formatTimestamp(text) ),

		},

	];


	const context = useContext(AppSettings);
	useEffect(() => {
		context.setAppHeaderNone(false);
		context.setAppSidebarNone(false);
		context.setAppContentClass('');
		context.setAppThemePanal(true)

		return function cleanUp() {
			context.setAppHeaderNone(true);
			context.setAppSidebarNone(true);
			context.setAppContentClass('p-0');
			context.setAppThemePanal(false)
		};
	}, []);

	useEffect(() => {
		if (!error && !isLoading) {
			return
		}
		toast.error(error)
		dispatch(clearError())
	}, [error, isLoading])

	if (!can("view_videourl")) {
		return <NoPermission />
	}
	return (
		<div>
			<Loader isLoading={isLoading} />
			<Card>
				<CardHeader>
					Inquire Links
				</CardHeader>
				<CardBody>
					<div className="mb-3">
						<label htmlFor='urls'>URLs (separated by new lines)</label>
						<br />
						<textarea
							name="urls"
							value={validation.values.urls}
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							placeholder='Enter url'
							style={{ height: "250px", width: '100%', backgroundColor: "transparent", border: '1px solid white', padding: "8px" }}
						/>
						{validation.touched.urls && validation.errors.urls && (
							<p className='text-danger'>{validation.errors.urls}</p>
						)}
					</div>

				</CardBody>
				<CardFooter>
					<div className='flex gap-4'>
						<button type="submit" className="btn btn-outline-theme" onClick={(e) => validation.handleSubmit()}
							disabled={isLoading}
						>
							Submit
						</button>
					</div>
				</CardFooter>
			</Card>

			{videos ?
				<ReusableTable
					data={videos}
					columns={columns}
				/>
				: null
			}
		</div>
	)
}

export default GetLinksDetails;