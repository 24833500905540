import { Select } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listVideosTypes } from '../redux/apis/videosApi'
import { clearError } from '../redux/slices/videoSlice'
import { toast } from 'react-toastify'

const VideoTypesDropdown = ({ onChange, onBlur, value, className = "", title }) => {
    const dispatch = useDispatch()
    const { videosTypes, isLoading, error } = useSelector(state => state.video)
    useEffect(() => {
        if (!videosTypes) {

            dispatch(listVideosTypes({
				page: '*',
				ordering: 'id',
			}))
        }
    }, [])

	useEffect(()=>{
        if ( !error && !isLoading) {
            return
        }
        toast.error(error)
        dispatch(clearError())
    },[error, isLoading])

    return (
        <div className=" mb-3">
            <label className="" >{title}</label>
            <br />
            <Select
                className={className}
                name='take_down_type'
                showSearch
                style={{
                    width: "100%"
                }}
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                defaultValue={value}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                loading={isLoading}
                options={videosTypes?.map(x => {
                    return { label: x.name, value: x.id }
                })}
            />
        </div>
    )
}

export default VideoTypesDropdown