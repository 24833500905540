import React, { useContext, useEffect } from 'react';
import { AppSettings } from '../../config/app-settings';
import { useDispatch, useSelector } from 'react-redux';
import { listUsers } from '../../redux/apis/customerApi';
import ReusableTable from '../../components/other/ReuseableTable';
import Loader from '../pages/loader';
import { useState } from 'react';
import EditCreateCustomer from '../../components/Customers/EditCreateCustomer';
import CustomerPlatforms from '../../components/Customers/CustomerPlatforms';
import { can } from '../../helpers/auth';
import NoPermission from '../pages/noPermission';
import { toast } from 'react-toastify';
import { clearError, clearSuccess } from '../../redux/slices/customerSlice';

const Customers = () => {

    const dispatch = useDispatch();
    const { customers, isLoading, error, success } = useSelector(state => state.customers);
    const [holder, setHolder] = useState()
    const [edit, setEdit] = useState(false)
    const [create, setCreate] = useState(false)
    const [platform, setPlatform] = useState(false)

    useEffect(() => {
        if (!customers || !customers?.[0]?.last_updated_by) {
            dispatch(listUsers({
                expand: ['country', 'last_updated_by'].join(','),
                page: '*',
                ordering: 'id'
            }));
        }
    }, [customers, dispatch]);

    const columns = [
        { title: "ID", dataIndex: "id", key: "id", sorter: true },
        {
            title: 'Customer Name',
            dataIndex: 'customer_name',
            key: 'customer_name',
            sorter: true,
            search: true,
        },
        {
            title: 'Customer Business',
            dataIndex: 'customer_business',
            key: 'customer_business',
            sorter: true,
            search: true,
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country.country_name',
            sorter: true,
            search: true,
            render: (country) => country ? country.country_name : '',
        },
        {
            title: 'Last Updated By',
            dataIndex: 'last_updated_by',
            key: 'last_updated_by',
            render: (text, record) => {
                const { last_updated_by } = record;
                return last_updated_by ? `${last_updated_by.first_name} ${last_updated_by.last_name}` : '';
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <div className='flex gap-2'>
                    <button className='btn btn-outline-theme' onClick={() => handleEdit(record)}>Edit</button>
                    <button className='btn btn-outline-light' onClick={() => handlePlatforms(record)}>Platforms</button>
                </div>
            ),
        },
    ];

    const handleEdit = (record) => {
        setHolder(record)
        setEdit(true)
    };

    const handlePlatforms = (record) => {
        setHolder(record)
        setPlatform(true)
    };

    const context = useContext(AppSettings);
    useEffect(() => {
        context.setAppHeaderNone(false);
        context.setAppSidebarNone(false);
        context.setAppContentClass('');
        context.setAppThemePanal(true);

        return function cleanUp() {
            context.setAppHeaderNone(true);
            context.setAppSidebarNone(true);
            context.setAppContentClass('p-0');
            context.setAppThemePanal(false);
        };
    }, []);

    useEffect(() => {
        if (!error && !isLoading) {
            return
        }
        toast.error(error)
        dispatch(clearError())
    }, [error, isLoading])


    useEffect(() => {
        if (success) {
            toast.success("Action successfuly")
            dispatch(clearSuccess())
        }
    }, [success])

    if (!can("view_customer")) {
        return <NoPermission />
    }
    return (
        <div className='w-full flex flex-col'>
            <Loader isLoading={isLoading} />
            {edit ? <EditCreateCustomer state={"edit"} open={edit} setOpen={setEdit} record={holder} /> : null}
            {create ? <EditCreateCustomer state={"create"} open={create} setOpen={setCreate} record={null} /> : null}
            {platform ? <CustomerPlatforms open={platform} setOpen={setPlatform} customer={holder} /> : null}
            <button className='btn btn-outline-theme ml-auto' onClick={() => setCreate(true)}>Create Customer</button>
            <br />
            {customers ?
                <ReusableTable
                    data={customers}
                    columns={columns}
                />
                : null
            }
        </div>
    );
};

export default Customers;
