import React, { useContext, useEffect, useState } from 'react';
import { AppSettings } from '../../config/app-settings';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../pages/loader';
import ReusableTable from '../../components/other/ReuseableTable';
import { listPlatforms } from '../../redux/apis/platformApi';
import EditCreatePlatform from '../../components/platforms/EditCreatePlatform';
import { can } from '../../helpers/auth';
import NoPermission from '../pages/noPermission';
import { toast } from 'react-toastify';
import { clearError, clearSuccess } from '../../redux/slices/platformSlice';

function Platforms() {


    const dispatch = useDispatch();
    const { platforms, isLoading, error, success } = useSelector(state => state.platform);
    const [edit, setEdit] = useState(false)
    const [create, setCreate] = useState(false)
    const [holder, setHolder] = useState()

    useEffect(() => {
        console.log(platforms)
        if (!platforms || !platforms?.[0]?.last_updated_by) {
            dispatch(listPlatforms({
                expand: ['last_updated_by'].join(','),
                page: '*',
                ordering: '-id'
            }));
        }
    }, [platforms]);

    const columns = [
        { title: "ID", dataIndex: "id", key: "id", sorter: true },
        {
            title: 'Platform Name',
            dataIndex: 'platform_name',
            key: 'platform_name',
            sorter: true,
            search: true,
        },

        {
            title: 'Platform Description',
            dataIndex: 'description',
            key: 'description',
            sorter: true,
            search: true,
        },
        {
            title: 'Last Updated By',
            dataIndex: 'last_updated_by',
            key: 'last_updated_by',
            render: (text, record) => {
                const { last_updated_by } = record;
                return last_updated_by ? `${last_updated_by.first_name} ${last_updated_by.last_name}` : '';
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <div className='flex gap-2'>
                    <button className='btn btn-outline-theme' onClick={() => handleEdit(record)}>Edit</button>
                </div>
            ),
        },
    ];

    const handleEdit = (record) => {
        setHolder(record)
        setEdit(true)
    };

    useEffect(() => {
        if (success) {
            toast.success("Action successfuly")
            dispatch(clearSuccess())
        }
    }, [success])


    const context = useContext(AppSettings);
    useEffect(() => {
        context.setAppHeaderNone(false);
        context.setAppSidebarNone(false);
        context.setAppContentClass('');
        context.setAppThemePanal(true)

        return function cleanUp() {
            context.setAppHeaderNone(true);
            context.setAppSidebarNone(true);
            context.setAppContentClass('p-0');
            context.setAppThemePanal(false)
        };
    }, []);

    useEffect(() => {
        if (!error && !isLoading) {
            return
        }
        toast.error(error)
        dispatch(clearError())
    }, [error, isLoading])

    if (!can("view_platform")) {
        return <NoPermission />
    }

    return (
        <div className='w-full flex flex-col'>
            <Loader isLoading={isLoading} />
            {edit ? <EditCreatePlatform state={"edit"} open={edit} setOpen={setEdit} platform={holder} /> : null}
            {create ? <EditCreatePlatform state={"create"} open={create} setOpen={setCreate} platform={null} /> : null}
            <button className='btn btn-outline-theme ml-auto' onClick={() => setCreate(true)}>Add New Platform</button>
            <br />
            {platforms ?
                <ReusableTable
                    data={platforms}
                    columns={columns}
                />
                : null
            }
        </div>
    )
}

export default Platforms;