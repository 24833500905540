import React, { useContext, useEffect } from 'react';
import { AppSettings } from '../../config/app-settings';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { opsPerformance, reportsOpsPerformance } from '../../redux/apis/analyticsApi';
import Loader from "../pages/loader"
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { Card, CardBody, CardFooter, CardHeader } from '../../components/other/card/card'
import generateExcel from '../../helpers/generateExcel';
import PerformanceChart from '../../components/OpsPerformance/PerformanceChart';
import { toast } from 'react-toastify';
import { clearError } from '../../redux/slices/analyticsSlice';

function OpsPerformance() {
	const dispatch = useDispatch()
	const { opsPerformanceData, reportsOpsPerformanceData, isLoading, error } = useSelector(state => state.analytics)

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			start_date: null,
			end_date: null,
		},
		validationSchema: Yup.object({
			start_date: Yup.date().max(new Date(Date.now() - 86400000).toISOString().split("T")[0], "Start Date must me a date before today").nullable(),
			end_date: Yup.date().max(new Date().toISOString().split("T")[0], "End Date must me a date before tomorrow")
				.min(Yup.ref('start_date'), "End Date can't be before the Start Date.").nullable(),
		}),
		onSubmit: (values) => {
			if (Object.keys(validation.errors).length !== 0) {
				console.log("validation error")
				return
			}
			dispatch(reportsOpsPerformance({
				start_date: values.start_date,
				end_date: values.end_date
			}))
			dispatch(opsPerformance({
				start_date: validation.values.start_date,
				end_date: validation.values.end_date
			}))
		},
	})

	useEffect(() => {
		if (!opsPerformanceData) {
			dispatch(opsPerformance())
		}
	}, [])

	useEffect(() => {
		if (reportsOpsPerformanceData) {
			if (!reportsOpsPerformanceData.length) {
				toast.info("No Data on this date range");
				validation.resetForm();
			}
			else {
				// toast.success("Downloading report, Please wait");
				generateExcel(reportsOpsPerformanceData, `OpsPerformance_Report_${validation.values.start_date}__${validation.values.end_date}`);
				validation.resetForm();
			}
		}

	}, [reportsOpsPerformanceData])

	

	const context = useContext(AppSettings);
	useEffect(() => {
		context.setAppHeaderNone(false);
		context.setAppSidebarNone(false);
		context.setAppContentClass('');
		context.setAppThemePanal(true)

		return function cleanUp() {
			context.setAppHeaderNone(true);
			context.setAppSidebarNone(true);
			context.setAppContentClass('p-0');
			context.setAppThemePanal(false)
		};
	}, []);


	useEffect(() => {
		if (!error && !isLoading) {
			return
		}
		toast.error(error)
		dispatch(clearError())
	}, [error, isLoading])

	return (
		<div className='flex flex-col gap-8'>
			<Loader isLoading={isLoading?.opsPerformance || isLoading.reportsOpsPerformance} />
			<Card>
				<CardHeader>Generate Report</CardHeader>
				<CardBody>

					<div className='flex gap-8'>

						<div className=" mb-3 customeDate">
							<label className="" >Start Date</label>
							<br />
							<DatePicker
								name='report_date'
								onChange={(e, date) => validation.setFieldValue("start_date", date)}
								onBlur={validation.handleBlur}
								value={validation.values.start_date ? dayjs(validation.values.start_date) : ""}
							/>
						</div>
						<div className=" mb-3 customeDate">
							<label className="" >End Date</label>
							<br />
							<DatePicker
								name='report_date'
								onChange={(e, date) => validation.setFieldValue("end_date", date)}
								onBlur={validation.handleBlur}
								value={validation.values.end_date ? dayjs(validation.values.end_date) : ""}
							/>
						</div>
					</div>
				</CardBody>
				<CardFooter>
					<div className='flex flex-col'>
						{Object.keys(validation.errors).map((key) => (
							<p className='text-danger'>{validation.errors[key]}</p>
						))}
					</div>
					<button type="submit" className="btn btn-outline-theme" onClick={(e) => validation.handleSubmit()}
						disabled={isLoading.reportsOpsPerformance}
					>
						Generate
					</button>
				</CardFooter>
			</Card>

			<Card>
				<CardHeader>Ops Performance Chart</CardHeader>
				<CardBody>
					<PerformanceChart data={opsPerformanceData?.by_date} />
				</CardBody>
			</Card>
		</div>
	)
}

export default OpsPerformance;