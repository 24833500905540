import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bulkCreatePlatforms, listCustomerPlatforms } from '../../redux/apis/customerApi'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { Button, Modal } from 'antd';
import PlatformsDropdown from '../PlatformsDropdown';
import Loader from '../../pages/pages/loader'
import {clearCampaigns} from '../../redux/slices/campaignSlice'

const CustomerPlatforms = ({ open, setOpen, customer }) => {
  const dispatch = useDispatch()
  const { platforms, isLoading } = useSelector(state => state.customers)
  useEffect(() => {
    dispatch(listCustomerPlatforms({
      customer: customer.id
    }))
  }, [])


  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      customer: customer.id,
      platforms: platforms?.platforms
    },
    validationSchema: Yup.object({
      customer: Yup.number().required('Customer is required'),
      platforms: Yup.array().nullable()
    }),
    onSubmit: async (values) => {
      dispatch(bulkCreatePlatforms(values))
      if (Object.keys(validation.errors).length === 0) {
        dispatch(clearCampaigns())
        setOpen(false)
      }
    }
  });

  return (
    <Modal
      open={open}
      title="Edit Customer Platforms"
      onCancel={() => setOpen(false)}
      footer={[
        <Button key="back" onClick={() => setOpen(false)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => {
          if (Object.keys(validation.errors).length !== 0) {
            Object.keys(validation.errors).map((key) => {
              // toast.error(validation.errors[key]);
            });
            return;
          }
          validation.handleSubmit();
        }}>
          Submit
        </Button>
      ]}
    >
      <Loader isLoading={isLoading}/>
      {validation.values.platforms ?
        <PlatformsDropdown
          value={validation.values.platforms}
          onChange={e=>validation.setFieldValue("platforms",e)}
          onBlur={validation.onBlur}

        />
        : null}

    </Modal>
  )
}

export default CustomerPlatforms