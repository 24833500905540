import React, { useContext, useEffect, useState } from 'react';
import { AppSettings } from '../../config/app-settings';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../pages/loader';
import EditCreateVideoTypes from '../../components/video/EditCreateVideoTypes';
import ReusableTable from '../../components/other/ReuseableTable';
import { listVideosTypes } from '../../redux/apis/videosApi';
import { can } from '../../helpers/auth';
import NoPermission from '../pages/noPermission';
import { clearError, clearSuccess } from '../../redux/slices/videoSlice';
import { toast } from 'react-toastify';

function VideoTypes() {

	const dispatch = useDispatch();
	const { videosTypes, isLoading, error, success } = useSelector(state => state.video);
	const [edit, setEdit] = useState(false)
	const [create, setCreate] = useState(false)
	const [holder, setHolder] = useState()

	useEffect(() => {
		if (!videosTypes) {
			dispatch(listVideosTypes({
				page: '*',
				ordering: 'id',
			}));
		}
	}, []);

	function formatTimestamp(timestamp) {
		const date = new Date(timestamp);

		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0'); // months are zero-indexed
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	}

	let columns = [
		{ title: "ID", dataIndex: "id", key: "id", sorter: true },
		{
			title: 'Type',
			dataIndex: 'name',
			key: 'name',
			sorter: true,
			search: true,
		},

		{
			title: 'Updated At',
			dataIndex: 'updated_at',
			key: 'updated_at',
			render: (text) => (formatTimestamp(text)),
		},
		{
			title: 'Created At',
			dataIndex: 'created_at',
			key: 'created_at',
			render: (text) => (formatTimestamp(text)),
		},

	];

	if (can("change_videotype")) {
		columns.push({
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<div className='flex gap-2'>
					<button className='btn btn-outline-theme' onClick={() => handleEdit(record)}>Edit</button>
				</div>
			),
		})
	}

	const handleEdit = (record) => {
		setHolder(record)
		setEdit(true)
	};


	const context = useContext(AppSettings);
	useEffect(() => {
		context.setAppHeaderNone(false);
		context.setAppSidebarNone(false);
		context.setAppContentClass('');
		context.setAppThemePanal(true)

		return function cleanUp() {
			context.setAppHeaderNone(true);
			context.setAppSidebarNone(true);
			context.setAppContentClass('p-0');
			context.setAppThemePanal(false)
		};
	}, []);

	useEffect(() => {
		if (!error && !isLoading) {
			return
		}
		toast.error(error)
		dispatch(clearError())
	}, [error, isLoading])


	useEffect(() => {
		if (success) {
			toast.success("Action successfuly")
			dispatch(clearSuccess())
		}
	}, [success])

	if (!can("view_videotype")) {
		return <NoPermission />
	}
	return (
		<div className='w-full flex flex-col'>
			<Loader isLoading={isLoading} />
			{edit ? <EditCreateVideoTypes state={"edit"} open={edit} setOpen={setEdit} videoType={holder} /> : null}
			{create ? <EditCreateVideoTypes state={"create"} open={create} setOpen={setCreate} videoType={null} /> : null}
			<button className='btn btn-outline-theme ml-auto' onClick={() => setCreate(true)}>Add New Video Type</button>
			<br />
			{videosTypes ?
				<ReusableTable
					data={videosTypes}
					columns={columns}
				/>
				: null
			}
		</div>
	)
}

export default VideoTypes;