// menuConfig.js

const getMenuItems = (isSuperuser) => {
    return [
        { is_header: true, title: 'MENU' },
        { path: '/dashboard', icon: 'fas fa-bars', title: 'Dashboard' },
        { path: '/campaigns-analytics', icon: 'fas fa-chart-pie', title: 'Campaigns analytics' },
        { is_divider: true }, 
        { is_header: true, title: 'SECTIONS' },
        { path: '/customers', icon: 'fas fa-user', title: 'Customers' },
        {
            path: '/campaigns', icon: 'far fa-life-ring', title: 'Campaigns',
            children: [
                { path: '/campaigns/campaigns-info', title: 'Campaigns Info' },
                { path: '/campaigns/campaigns-loa', title: 'Campaigns LOA' },
            ]
        },
        { path: '/platforms', icon: 'fas fa-asterisk', title: 'Platforms' },
        { path: '/video-durations', icon: 'fas fa-file-video', title: 'Video Durations' },
        { path: '/video-types', icon: 'fas fa-film', title: 'Video Types' },
        {
            path: "/link-insertion", icon: 'fas fa-link', title: 'Links Insertion', children: [
                { path: '/link-insertion/facebook', title: 'Facebook' },
                { path: '/link-insertion/instagram', title: 'Instagram' },
                { path: '/link-insertion/twitter', title: 'Twitter' },
                { path: '/link-insertion/youtube', title: 'YouTube' },
                { path: '/link-insertion/tiktok', title: 'TikTok' },
                { path: '/link-insertion/twitch', title: 'Twitch' },
                { path: '/link-insertion/snapchat', title: 'Snapchat' },

            ]
        },
        {
            path: "/link-marking", icon: 'fas fa-edit', title: 'Links Marking', children: [
                { path: '/link-marking/social-media-marking', title: 'Social Media Marking' },
            ]
        },
        {
            path: "/update-links", icon: 'fas fa-arrow-alt-circle-up', title: 'Update Links', children: [
                { path: '/update-links/change-links-campaign', title: 'Change Links Campaign' },
            ]
        },
        {
            path:"/inquiries",icon: 'fas fa-clone', title: 'Inquiries', children: [
                { path: '/inquiries/get-links-details', title: 'Get Links Details' },
            ]
        },
        {
            path:"/contentid-links-insertion",icon: 'far fa-file', title: 'ContentID Links Insertion', children: [
                { path: '/contentid-links-insertion/facebook', title: 'Facebook' },
                { path: '/contentid-links-insertion/instagram', title: 'Instagram' },
                { path: '/contentid-links-insertion/youtube', title: 'Youtube' },

            ]
        },
        { path: '/whitelist-channels', icon: 'fas fa-tv', title: 'Whitelist Channels' },
        isSuperuser && {
            path:"/auth", icon: 'fas fa-lock', title: 'Auth', children: [
                { path: '/auth/users', title: 'Users' },
                { path: '/auth/permissions', title: 'Permissions' },
                { path: '/auth/groups', title: 'Groups' },
            ]
        },
        { path: '/ops-performance', icon: 'fas fa-star', title: 'Ops Performance' },
        { path: '/generate-reports', icon: 'far fa-file-archive', title: 'Generate Reports' },
        { path: '/background-tasks', icon: 'fas fa-cogs', title: 'Background Tasks' },
    ].filter(Boolean);
};

export default getMenuItems;
