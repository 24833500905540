import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup"
import * as XLSX from 'xlsx'
import { createVideo } from '../../redux/apis/videosApi';
import { Card, CardBody, CardFooter, CardHeader } from '../../components/other/card/card'
import { getUserId } from '../../helpers/auth'
import { toast } from 'react-toastify';
import { clearLinkInsertion, clearSuccess } from '../../redux/slices/videoSlice';
import generateExcel from '../../helpers/generateExcel';


const LinksInsertion = ({ platform_name, platformId, matchText = [], isContentID = false }) => {
    const dispatch = useDispatch()
    const { isLoading, linkInsertion, success } = useSelector(state => state.video)
    const filePicker = useRef(null);

    const validation = useFormik({
        // enableReinitialize: use this flag when initial values need to be changed
        enableReinitialize: true,

        initialValues: {
            user: getUserId(),
            urls: []
        },
        validationSchema:
            Yup.object({
                urls: Yup.array().min(1, "At least 1 url should be valid and inserted")
            })
        ,
        onSubmit: async (values) => {
            dispatch(createVideo(values.urls))
            toast.info("Adding Video Urls, please wait.")
        },
    });
    useEffect(() => {
        if (!linkInsertion) return;

        if (linkInsertion?.invalid > 0) {

            function convertToExcelRows(data) {
                const rows = [];
            
                // Iterate over each key-value pair in the input data
                for (const [url, details] of Object.entries(data)) {
                    // Create a row object for each entry
                    const row = {
                        "URL": url,
                        "status": details.status,
                        "error": details.error
                    };
                    // Push the row into the rows array
                    rows.push(row);
                }
            
                return rows;
            }

            generateExcel(convertToExcelRows(linkInsertion?.detailed_report), `${platform_name} url insertion errors`);

            // Show the error message in the toast
            toast.error("error adding urls");
            dispatch(clearLinkInsertion())
        }
        if (linkInsertion?.invalid == 0) {
            toast.success(`Urls added successfully, \n "inserted": ${linkInsertion?.inserted},
                "whitelisted": ${linkInsertion?.whitelisted}.`
            )
            dispatch(clearLinkInsertion())
        }
    }, [linkInsertion]);


    useEffect(() => {
        if (success && linkInsertion?.invalid == 0 && linkInsertion?.inserted > 29) {
            toast.success("Background task started")
            clearSuccess()
        }
    }, [linkInsertion, success])


    const handleFile = async (e) => {
        if (!e.target.files[0]) return false;

        const file = e.target.files[0];
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, { cellDates: true });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        let jsonData = XLSX.utils.sheet_to_json(worksheet);
        const invalidData = [];

        jsonData = jsonData.map(item => processItem(item, invalidData)).filter(Boolean); // Remove nulls

        if (invalidData.length > 0) {
            showInvalidDataMessage(invalidData);
        }

        validation.setFieldValue("urls", jsonData);
    };

    const atLeastOneMatches = (url, arr) => {
        for (let item of arr) {
            if (url.match(item)) {
                return true;
            }
        }
        return false;
    }

    const processItem = (item, invalidData) => {
        const formattedDate = getCurrentFormattedDate();
        item['review_date'] = item.report_date || formattedDate;
        item['link_type'] = 'related';
        item['platform'] = platformId;
        item['video_data'] = extractVideoData(item);
        item['campaign'] = item.campaign_id
        if (isContentID) {
            item["is_content_id"] = true
        }

        if (!isValidItem(item, invalidData)) {
            return null;
        }

        cleanUpItem(item);
        return item;
    };

    const getCurrentFormattedDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getUTCHours()).padStart(2, '0');
        const minutes = String(now.getUTCMinutes()).padStart(2, '0');
        const seconds = String(now.getUTCSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}+00`;
    };

    const extractVideoData = (item) => ({
        video_title: item['video_title'],
        video_description: item['video_description'],
        video_tags: item['video_tags'],
        video_total_views: item['video_total_views'],
        video_duration: item['video_duration'],
        video_published_at: item['video_published_at'],
        channel_name: item['channel_name'],
        video_type_id: item['video_type'],
        comments: item['comments'],
    });

    const isValidItem = (item, invalidData) => {
        const isUrlValid = item["url"] && atLeastOneMatches(item["url"], matchText);
        const isReportDateValid = !isContentID || item['report_date'];

        if (!isUrlValid) {
            invalidData.push(item['url']);
            return false;
        }
        if (!isReportDateValid) {
            invalidData.push(item['url']);
            return false;
        }
        return true;
    };

    const cleanUpItem = (item) => {
        const keysToRemove = [
            'video_title',
            'video_description',
            'video_tags',
            'video_total_views',
            'video_duration',
            'video_published_at',
            'channel_name',
            'video_type',
            'comments',
            'campaign_id'
        ];
        keysToRemove.forEach(key => delete item[key]);
    };

    const showInvalidDataMessage = (invalidData) => {
        const message = `Invalid URLs:\n${invalidData.join('\n')} \nMake sure it is a valid ${platform_name} and if it is a content ID, make sure the report date is inserted`;
        toast.error(message);
    };

    return (
        <div>
            <Card>
                <CardHeader>
                    Upload {platform_name} Result Sheet
                </CardHeader>
                <CardBody>
                    <div className="mb-3">
                        <label htmlFor="urls">Upload xlsx file</label>
                        <br />
                        <input
                            ref={filePicker}
                            type="file"
                            onChange={(e) => handleFile(e)}
                            accept=".xlsx, .xls"
                            name='urls'
                            id='urls'
                        />
                        {validation.errors.urls && validation.touched.urls && (
                            <p className="text-danger mt-4">
                                {validation.errors.urls}
                            </p>
                        )}
                    </div>
                </CardBody>
                <CardFooter>
                    <div className='flex gap-4'>
                        <button type="submit" className="btn btn-outline-theme" onClick={(e) => validation.handleSubmit()}
                            disabled={isLoading}
                        >
                            Submit
                        </button>
                        <button type='button' className="btn btn-outline-warning" onClick={() => {
                            window.location.reload()
                        }} >Reset</button>
                    </div>
                </CardFooter>
            </Card>
        </div>
    )
}

export default LinksInsertion