import { Select } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listUsers } from '../redux/apis/authApi'
import { getUserId } from '../helpers/auth'
import { clearError } from '../redux/slices/authSlice'
import { toast } from 'react-toastify'

const UsersDropdown = ({ onChange, onBlur, value, filters = {}, insertion = false, title, className = "" }) => {
    const dispatch = useDispatch()
    const { users, isLoading, error } = useSelector(state => state.auth)
    let options

    useEffect(() => {
        if (!users) {

            dispatch(listUsers({
                page: '*',
                ordering: 'first_name,last_name',
                fields: 'id,first_name,last_name',
                ...filters
            }))
        }
    }, [])

    if (users) {
        options = users?.map(x => {
            return {
                label: insertion ? x.last_name : `${x.first_name} ${x.last_name}`,
                value: x.id
            }
        })
        if (insertion) {
            options = [{
                label: 'Me',
                value: getUserId()
            }, ...options]
        }
    }


    useEffect(() => {
        if (!error && !isLoading) {
            return
        }
        toast.error(error)
        dispatch(clearError())
    }, [error, isLoading])


    return (
        <div className=" mb-3">
            <label className="" >{title}</label>
            <br />
            <Select
                className={className}
                name='user'
                showSearch
                style={{
                    width: "100%"
                }}
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                defaultValue={value}
                value={value}
                onChange={e => onChange("user", e)}
                onBlur={onBlur}
                loading={isLoading}
                options={options}
            />
        </div>
    )
}

export default UsersDropdown