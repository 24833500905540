import React, { useState } from 'react'
import { Card, CardBody, CardExpandToggler, CardHeader } from '../other/card/card'
import { Badge } from 'antd'

const TaskDefinitionCard = ({ task }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const colorMap = {
        'QUEUED': 'warning',
        'STARTED': 'info',
        'SUCCESS': 'success',
        'FAILURE': 'danger',
    }

    const handleCardExpand = (expanded) => {
        setIsExpanded(expanded);
    }
    return (
        <Card>
            <CardHeader className="fw-bold small d-flex">
                <span className="flex-grow-1">
                    {task?.task.task_name}
                </span>
                <CardExpandToggler className="z-50" onExpandChange={handleCardExpand} />
            </CardHeader>
            <CardBody>
                <div className='flex gap-4'>

                    <p>
                        Created At: <br />
                        {formatDateTime(task?.task?.date_created)}
                        <br />
                        Done At: <br />
                        {formatDateTime(task?.task?.date_done)}
                        <br />
                        {isExpanded && task?.task?.status === 'FAILURE' && (
                            <p>Result: {task?.task?.result}</p>
                        )}
                    </p>
                    <span className={`badge bg-${colorMap[task?.task.status]} h-4`}>{task?.task.status}</span>
                    
                </div>
            </CardBody>
        </Card>
    )
}

export default TaskDefinitionCard

function formatDateTime(isoString) {
    const date = new Date(isoString);

    // Extract date parts
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();

    // Extract time parts
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    // Format as dd-mm-yyyy HH:MM
    return `${day}-${month}-${year} ${hours}:${minutes}`;
}